import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static outlets = ['campaign-editor'];

  static targets = ['lastSaved'];

  save() {
    this.campaignEditorOutlet.submit();
  }

  updateTimeAgo(timeAgo) {
    this.lastSavedTarget.textContent = timeAgo;
  }
}
