import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'uploadingNotice',
    'importingNotice',
    'hubspotListSelect',
    'csvFileInput',
    'nameInput',
    'submitButton',
  ];

  static values = { submitButtonDisabled: Boolean };

  connect() {
    this.maybeEnableSubmitButton();
  }

  submitButtonTargetConnected() {
    this.maybeEnableSubmitButton();
  }

  showUploadingNotice() {
    this.importingNoticeTarget.classList.add('hidden');
    this.uploadingNoticeTarget.classList.remove('hidden');
  }

  showImportingNotice() {
    this.uploadingNoticeTarget.classList.add('hidden');
    this.importingNoticeTarget.classList.remove('hidden');
  }

  disableSubmitButton() {
    this.submitButtonDisabledValue = true;
    this.submitButtonTarget.disabled = true;
  }

  markNameAsCustomized() {
    this.customizedName = true;
  }

  setDefaultName(value) {
    if (this.customizedName) return;

    this.nameInputTarget.value = value;

    this.maybeEnableSubmitButton();
  }

  setDefaultNameFromSelect(e) {
    const selectedOption = e.target.options[e.target.selectedIndex];

    this.setDefaultName(selectedOption.text);
  }

  setDefaultNameFromFileInput(e) {
    const fileNameWithExt = e.target.value.split('\\').pop();
    const fileName = fileNameWithExt.split('.')[0];

    this.setDefaultName(fileName);
  }

  maybeEnableSubmitButton() {
    if (!this.hasSubmitButtonTarget) return;

    let disabled = this.submitButtonDisabledValue;

    const requiredToEnableSubmitButtonTargets = [this.nameInputTarget];
    if (this.hasCsvFileInputTarget) requiredToEnableSubmitButtonTargets.push(this.csvFileInputTarget);
    if (this.hasHubspotListSelectTarget) requiredToEnableSubmitButtonTargets.push(this.hubspotListSelectTarget);

    requiredToEnableSubmitButtonTargets.forEach((field) => {
      if (field.value === '') disabled = true;
    });

    this.submitButtonTarget.disabled = disabled;
  }
}
