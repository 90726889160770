import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['dropdownContent', 'filterInput', 'option', 'hiddenInput'];

  connect() {
    this.filterInputTargetValueWas = '';
  }

  filterOptions(event) {
    const filter = event.target.value.toLowerCase();

    this.optionTargets.forEach((option) => {
      const optionText = option.dataset.name.toLowerCase();
      option.classList.toggle('hidden', !optionText.includes(filter));
    });
  }

  selectOption(event) {
    let selectedOption;
    this.optionTargets.forEach((option) => {
      option.classList.remove('bg-blue-500', 'text-white');
      if (option.dataset.value === event.params.value.toString()) {
        selectedOption = option;
      }
    });

    selectedOption.classList.add('bg-blue-500', 'text-white');

    this.filterInputTarget.value = event.params.name;
    this.hiddenInputTarget.value = event.params.value;
    this.filterInputTargetValueWas = event.params.name;

    this.dropdownContentTarget.blur();
  }

  clearFilterInput() {
    this.filterInputTarget.value = '';

    this.optionTargets.forEach((option) => {
      option.classList.remove('hidden');
    });
  }

  resetFilterInput(e) {
    if (e.relatedTarget) {
      if (this.element.contains(e.relatedTarget)) {
        return;
      }
    }

    this.filterInputTarget.value = this.filterInputTargetValueWas;
  }
}
