import { Controller } from '@hotwired/stimulus';
import tinymce from 'tinymce/tinymce';
import { done_segments_path } from '@root/routes';
import { clearTurboFrame } from '@root/utilities/utils';
import addElement, { reloadElement } from '../utils/add_element';

export default class extends Controller {
  static targets = ['iframe'];

  static values = { campaignId: Number, smartTagTemplateId: Number };

  connect() {
    this.iframeTarget.onload = () => {
      if (
        this.iframeTarget.contentWindow.location.pathname ===
        done_segments_path(this.campaignIdValue, this.smartTagTemplateIdValue)
      ) {
        const editor = tinymce.activeEditor;
        const element = editor.contentWindow.document.getElementById(
          `smart_tag_template-${this.smartTagTemplateIdValue}`,
        );

        if (!element) {
          addElement({
            json: {
              id: this.smartTagTemplateIdValue,
            },
          });
        } else {
          reloadElement(this.smartTagTemplateIdValue);
        }
        clearTurboFrame('segmentation-page');
      }
    };
  }
}
