import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';
import { integrations_path } from '@root/routes';

export default class extends Controller {
  static targets = [
    'senderIcon',
    'inputFromFriendly',
    'fromAddressContainer',
    'fromFriendlyContainer',
    'senderConfigWarningContainer',
    'defaultFromAddressTemplate',
    'defaultFromFriendlyTemplate',
    'defaultSenderConfigWarningTemplate',
    'zapierFromAddressTemplate',
    'zapierFromFriendlyTemplate',
    'zapierSenderConfigWarningTemplate',
    'sendgridFromAddressTemplate',
    'sendgridFromFriendlyTemplate',
    'sendgridSenderConfigWarningTemplate',
    'sendWithSingulateFromAddressTemplate',
    'sendWithSingulateFromFriendlyTemplate',
    'sendWithSingulateSenderConfigWarningTemplate',
    'sendWithHubspotFromAddressTemplate',
    'sendWithHubspotFromFriendlyTemplate',
    'sendWithHubspotSenderConfigWarningTemplate',
    'sendWithHubspotMarketingFromAddressTemplate',
    'sendWithHubspotMarketingFromFriendlyTemplate',
    'sendWithHubspotMarketingSenderConfigWarningTemplate',
    'deliverUsing',
    'subject',
  ];

  static values = {
    templateMapping: Object,
    lastProvider: String,
  };

  connect() {
    this.updateSenderProvider();
  }

  updateSenderProvider(event) {
    const value = event ? event.currentTarget.dataset.value : this.lastProviderValue;
    if (value === 'add') {
      this.addProvider();
      return;
    }
    const templateMapping = this.templateMappingValue[value] || this.templateMappingValue.default;
    this.deliverUsingTarget.value = value;

    const fromAddressTarget = this[templateMapping.fromAddress];
    const fromFriendlyTarget = this[templateMapping.fromFriendly];
    const senderConfigWarningTarget = this[templateMapping.senderConfigWarning];
    this.fromAddressContainerTarget.innerHTML = fromAddressTarget.innerHTML;
    this.fromFriendlyContainerTarget.innerHTML = fromFriendlyTarget.innerHTML;
    this.senderConfigWarningContainerTarget.innerHTML = senderConfigWarningTarget.innerHTML;
    this.fillSenderNameFromSelectedSenderEmail({ currentTarget: this.inputFromFriendlyTarget });
  }

  addProvider() {
    const url = integrations_path();
    Turbo.visit(url);
  }

  fillSenderNameFromSelectedSenderEmail(event) {
    const { value } = event.currentTarget;
    if (value) {
      this.senderIconTarget.innerHTML = value.charAt(0).toUpperCase();
    } else {
      this.senderIconTarget.innerHTML = '-';
    }
  }
}
